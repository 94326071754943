import React from "react";
import Home from "./pages/Home";

function App() {
    return (
        <React.Fragment>
            <Home />
        </React.Fragment>
    );
}

export default App;
